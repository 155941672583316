// extracted by mini-css-extract-plugin
export var africarColor = "dn_Bj";
export var ammoColor = "dn_z0";
export var babelColor = "dn_Bh";
export var cakerColor = "dn_z3";
export var chatticColor = "dn_z1";
export var danColor = "dn_z2";
export var dumaColor = "dn_Bb";
export var dvColor = "dn_Bl";
export var ifndColor = "dn_zZ";
export var impactColor = "dn_z6";
export var ixColor = "dn_Bm";
export var malleniColor = "dn_z5";
export var pmkColor = "dn_Bg";
export var refColor = "dn_Bc";
export var rewColor = "dn_Bd";
export var timebotColor = "dn_z8";
export var tradeaboatColor = "dn_z9";
export var tradervsColor = "dn_z7";
export var variaColor = "dn_z4";
export var wownerColor = "dn_Bf";
export var xpcColor = "dn_Bk";